<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Visualizar dados da triagem</h2>
        <hr />
        <v-form lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_cardiaca"
                label="Frequência cardíaca (Obrigatório)"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="temperatura"
                label="Temperatura"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="peso"
                label="Peso"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="altura"
                label="Altura"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="saturacao_oxigenio"
                label="Saturação de Oxigênio"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_respiratoria"
                label="Frequência respiratória"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="pressao_arterial"
                label="Pressão arterial"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                type="text"
                v-model="observacoes_triagem"
                label="Observações"
                required
              ></v-text-field>
            </div>
          </div>

          <router-link
            :to="
              this.$route.params.setor == 2
                ? '/FilaDeAtendimento'
                : '/FilaAtendimentoConsulta'
            "
            v-slot="{ href, navigate }"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle mt-6" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "visualizarDadosTriagem",
  async created() {
    try {
      const response = await ambulatorio.triagemPorProntuário(
        this.$route.params.id
      );

      this.frequencia_cardiaca = response.data.frequencia_cardiaca;
      this.temperatura = response.data.temperatura;
      this.peso = response.data.peso;
      this.altura = response.data.altura;
      this.saturacao_oxigenio = response.data.saturacao_oxigenio;
      this.frequencia_respiratoria = response.data.frequencia_respiratoria;
      this.observacoes_triagem = response.data.observacoes_triagem;
      this.pressao_arterial = response.data.pressao_arterial;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      frequencia_cardiaca: "",
      temperatura: "",
      peso: "",
      altura: "",
      saturacao_oxigenio: "",
      frequencia_respiratoria: "",
      pressao_arterial: "",
      observacoes_triagem: "",
      paciente_id: ""
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Visualizar Triagem" }]);
  }
};
</script>
